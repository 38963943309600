<template>
  <div class="yimiao">
    <div>
      <div style="
          border-bottom: 1px solid #e6e6e6;
          padding: 10px 15px;
          position: relative;
        ">
        <div style="display: inline-block; width: 60px">
          <img style="width: 100%"
            src="../../assets/矛盾隐患.png"
            alt="" />
        </div>
        <div style="display: inline-block; position: absolute; top: 30px;left:100px">
          <div style="font-size: 18px">场所防控措施</div>
        </div>
      </div>
      <div>
        <van-cell-group>
          <van-field v-model="datafrom.OrgName"
            required
            @click="idListOrg = true"
            readonly="readonly"
            label="社区"
            placeholder="请选择社区" />
          <van-popup v-model="idListOrg"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="OrganList"
              value-key="organname"
              @cancel="idListOrg = false"
              @confirm="onidListOrg">
            </van-picker>
          </van-popup>
        </van-cell-group>
        <!-- <van-cell-group>
          <van-field v-model="datafrom.Region"
            required
            @click="idListRId = true"
            readonly="readonly"
            label="所住小区(组)"
            placeholder="请选择您所住小区(组)" />
          <van-popup v-model="idListRId"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="ridlist"
              value-key="Title"
              @cancel="idListRId = false"
              @confirm="onidListRId">
            </van-picker>
          </van-popup>
        </van-cell-group> -->
        <van-cell-group>
          <van-field v-model="datafrom.SubmitMan"
            required
            label="姓 名"
            placeholder="请输入姓名" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.SubmitTel"
            label="联系电话"
            placeholder="请输入联系电话" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.Problem"
            required
            label="发现问题"
            placeholder="请输入发现问题" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.Address"
            required
            label="地 点"
            placeholder="请输入详细地址" />
        </van-cell-group>
        <div class="submit"
          style="padding:10px 7px;">
          <span style="color: red; font-size: 14px; letter-spacing: 12px"></span>
          <span style="color: #646566;font-size: 14px; ">问题图片(疫情防控现场措施)：</span>
        </div>
        <van-uploader v-model="jkImageList"
          multiple
          class="imgs"
          :max-count="9"
          :after-read="afterRead"
          :before-delete="jkDelete"
          :deletable="true" />

      </div>
      <!-- <div class="buttons">
        <van-button type="info" @click="postfrom()">提交</van-button>
      </div> -->
      <div id="allmap"
        style="height: 160px;width: 90%;margin: auto;"></div>
      <div class="buttons">
        <van-button type="info"
          style="margin:10px 0;"
          @click="postfrom()">提交</van-button>
        <van-button style="border: 1px solid #e6e6e6;margin:10px 0;"
          type="
          default"
          @click="fanhui()">返回</van-button>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript" src="//api.map.baidu.com/api?v=3.0&ak=L2s5R0CE405alnjKlsp35mNTySNAvZtd"></script>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
import { setOpenId, getOpenId } from "@/utils/auth";
import config from "@/config";
import Compressor from "compressorjs";
import axios from "axios";
import { loadBMap } from "./baiduLocation.js";
Vue.use(Toast);
import {
  WeGetRegionList,
  WeGetBuildRoomList,
  WxSaveMeasure,
  WeGetAppletOrganList,
  GetOrgansNullToken
} from "@/api/yeguang";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data () {
    return {
      // 健康码
      jkImageList: [],
      //行程码
      xcImageList: [],
      //核酸证明
      hsImageList: [],
      ridlist: [],
      BIdlist: [],
      BUIdlist: [],
      BFIdlist: [],
      BRIdlist: [],
      OrganList: [],
      diqu: [],
      guanxi: [],
      minzu: [],
      xveli: [],
      zhuangtai: [],
      hunyin: [],
      ocodelist: [],
      minDate: new Date(2021, 0),
      maxDate: new Date(2025, 0),
      NativeCantonCodedata: [],
      sexlist: [
        { id: "1", Name: "男" },
        { id: "2", Name: "女" },
      ],
      yimiaolist: [
        { id: "0", Name: "未接种" },
        { id: "1", Name: "第一针" },
        { id: "2", Name: "第二针" },
      ],
      idList: {
        organCode: "",
        RId: undefined,
      },
      address: {}, //经纬度信息
      datafrom: {
        MId: 0,  //id、
        OpenID: "",  //、
        OrgCode: "",
        SubmitMan: "",
        Nickname: "",
        HeadImg: "",
        Problem: "",
        Imgs: "",
        Address: "",
        Coordinate: "",
      },
      idListRId: false,
      idListOrg: false,
      addshow: false,
      verifyshow: true,
      OCodeshow: false,
      sexshow: false,
      Nationshow: false,
      yimiao: false,
      datafromBackTime: false,
      datafromOutTime: false,
      Coordinate: "",
      ImgsList: []
    };
  },
  created () {
    this.GetOrgansNullToken();
    var that = this;
    // 百度地图API功能
    window.initBaiduMapScript = () => {
      that.BMap = window.BMap;
      var map = new window.BMap.Map("allmap");
      var point = new that.BMap.Point(116.331398, 39.897445);
      map.centerAndZoom(point, 18);

      var geolocation = new that.BMap.Geolocation();
      geolocation.getCurrentPosition(function (r) {
        if (this.getStatus() == BMAP_STATUS_SUCCESS) {
          console.log(r);
          var mk = new BMap.Marker(r.point);
          map.addOverlay(mk);
          map.panTo(r.point);
          that.Coordinate = [{ "lng": r.point.lng, "lat": r.point.lat, "of": "inner" }]
          // alert('您的位置：' + r.point.lng + ',' + r.point.lat);
        }
        else {
          alert('failed' + this.getStatus());
        }
      }, { enableHighAccuracy: true })
    };
    loadBMap("initBaiduMapScript");
  },
  methods: {
    //上传问题图片
    afterRead (file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success (result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios
            .post(config.apiUploadUrl, formData, c)
            .then((res) => {
              if (res.data.code === 0) {
                console.log(res.data.data);
                // that.datafrom.Imgs = res.data.data
                that.ImgsList.push(res.data.data);
                file.status = "";
                file.message = "";
              } else {
                Toast.fail(res.data.msg);
              }
            })
        },
      });
    },
    //删除问题图片
    jkDelete (file, detail) {
      var that = this
      that.ImgsList.splice(detail.index, 1);
      this.jkImageList.splice(detail.index, 1);
      // this.jkImageList = []
      // this.ImgsList = ""
    },
    //上传行程码
    afterXcRead (file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success (result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios
            .post(config.apiUploadUrl, formData, c)
            .then((res) => {
              if (res.data.code === 0) {
                console.log(res.data.data);
                that.datafrom.TravelCode = res.data.data
                // that.datafrom.EvalImgs.push(res.data.data);
                file.status = "";
                file.message = "";
              } else {
                Toast.fail(res.data.msg);
              }
            })
        },
      });
    },
    //删除行程码
    xcDelete (file) {
      this.xcImageList = []
      this.datafrom.TravelCode = ""
    },
    //上传核酸证明
    afterhsRead (file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success (result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios
            .post(config.apiUploadUrl, formData, c)
            .then((res) => {
              if (res.data.code === 0) {
                console.log(res.data.data);
                that.datafrom.Testify = res.data.data
                // that.datafrom.EvalImgs.push(res.data.data);
                file.status = "";
                file.message = "";
              } else {
                Toast.fail(res.data.msg);
              }
            })
        },
      });
    },
    //删除核酸
    hsDelete (file) {
      this.hsImageList = []
      this.datafrom.Testify = ""
    },
    // 提交
    postfrom: function () {
      var that = this
      console.log(that.Coordinate);
      console.log(that.ImgsList.toString());
      if (this.datafrom.SubmitMan == "") {
        Toast.fail("请输入姓名");
        return false;
      }
      if (this.datafrom.OrgCode == "") {
        Toast.fail("请选择小区");
        return false;
      }
      // if (this.datafrom.Number == "") {
      //   Toast.fail("请输入房号");
      //   return false;
      // }
      // if (this.datafrom.SubmitTel == "") {
      //   Toast.fail("请输入联系电话");
      //   return false;
      // }
      if (this.datafrom.Problem == "") {
        Toast.fail("请输入发现问题");
        return false;
      }
      if (this.datafrom.Address == "") {
        Toast.fail("请输入地点");
        return false;
      }
      this.datafrom.OpenID = getOpenId();
      // this.datafrom.OpenID = "123321";
      this.datafrom.Coordinate = JSON.stringify(this.Coordinate)
      this.datafrom.Imgs = that.ImgsList + ""
      WxSaveMeasure(this.datafrom).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: "提交成功!",
          }).then(() => {
            // this.$router.push({
            //   name: "register",
            // });
            this.$router.go(-1)
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "提交失败!" + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      })
    },
    gitocodelist () {
      WeGetAppletOrganList().then((res) => {
        this.ocodelist = res.data.data;
      })
    },
    // 社区列表
    GetOrgansNullToken: function (row) {
      GetOrgansNullToken({ level: 4, kind: 1 }).then((res) => {
        this.OrganList = res.data.data;
      });
    },
    // 小区(组)列表
    RegionList: function (row) {
      WeGetRegionList({ organCode: row }).then((res) => {
        this.ridlist = res.data.data;
      });
    },
    // 房屋列表
    BuildRoomList: function (val) {
      WeGetBuildRoomList({
        bId: this.idList.BId,
        buId: this.idList.buId,
        bfId: val,
      }).then((res) => {
        console.log(res);
        this.BRIdlist = res.data.data;
      });
    },
    fanhui: function () {
      this.$router.push({
        name: "register",
      });
    },
    // 社区选择器
    onidListOrg (val) {
      this.datafrom.OrgCode = val.organcode;
      this.datafrom.OrgName = val.organname;
      this.idListOrg = false;
      // this.RegionList(val.organcode);
      this.datafrom.Region = "";
      // this.BuildList(val.RId);
    },
    // 小区(组)选择器
    onidListRId (val) {
      this.datafrom.RId = val.RId;
      this.datafrom.Region = val.Title;
      this.idListRId = false;
      this.BuildList(val.RId);
    },
    // 性别选择器
    onsexshow (val) {
      this.datafrom.sex = val.id;
      this.datafrom.sexName = val.Name;
      this.sexshow = false;
    },
    // 疫苗选择器
    onyimiao (val) {
      this.datafrom.yimiao = val.id;
      this.datafrom.yimiaoName = val.Name;
      this.yimiao = false;
    },
    // 返回时间
    ondatafromBackTime (val) {
      this.datafrom.BackTime = this.dateformat(val);;
      this.datafromBackTime = false;
    },
    // 前进时间
    ondatafromOutTime (val) {
      this.datafrom.OutTime = this.dateformat(val);;
      this.datafromOutTime = false;
    },
    // 社区
    onocode (val) {
      console.log(val);
      this.datafrom.OrgName = val.selectedOptions[0].OrganName;
      this.RegionList(val.value);
      this.OCodeshow = false;
    },
    dateformat (val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      let houser = val.getHours();
      let Minutes = val.getMinutes();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      if (houser >= 1 && houser <= 9) {
        houser = `0${houser}`;
      }
      if (Minutes >= 1 && Minutes <= 9) {
        Minutes = `0${Minutes}`;
      }
      return `${year}-${month}-${day} ${houser}:${Minutes}`;
    },
  },
};
</script>
<style>
.imgs {
  margin: 0 15px;
}
.yimiao .inputFrom {
  margin: 15px 8%;
}
.yimiao .inputFrom input {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.yimiao .buttons {
  text-align: center;
}
.yimiao .buttons button {
  width: 90%;
  margin: 25px 5%;
  border-radius: 10px;
}
.buttons {
  text-align: center;
}
.buttons button {
  margin: 25px 10px;
  border-radius: 10px;
}
</style>